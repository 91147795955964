<script  setup lang="ts">
import type { login_passwordPayload } from '~/api/generated';
import { LoginService } from '~/api/generated';

const emit = defineEmits(['close']);

const password = ref<string>();
const errorMessage = ref<string>();

async function sendPassword() {
  const loginData: login_passwordPayload = { password: password.value! };

  LoginService.cachePassword(loginData)
    .then(() => {
      emit('close');
    }).catch(() => {
      errorMessage.value = 'Falsches Passwort!';
    });
}
</script>

<template>
  <BaseModal
    title="Passwortabfrage"
    @submit="sendPassword()"
    @close="$emit('close')"
  >
    <template #body>
      <div class="flex flex-row items-center gap-4">
        <div class="w-full">
          Bitte geben Sie Ihr Passwort erneut ein, um fortzufahren.
          <o-field label="Passwort">
            <o-input
              v-model="password"
              type="password"
              required
              password-reveal
            />
          </o-field>
          <div v-if="errorMessage" class="text-sm text-red-500">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex justify-end gap-4"
      >
        <o-button
          variant="success"
          native-type="submit"
        >
          Absenden
        </o-button>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
