import Oruga from '@oruga-ui/oruga-next';
import type { UserModule } from '~/types';
import OrugaIcon from '~/components/OrugaIcon.vue';

import '@oruga-ui/oruga-next/dist/oruga-full.css';

// Setup Oruga
export const install: UserModule = ({ app }) => {
  app.component('OrugaIcon', OrugaIcon);
  app.use(Oruga, {
    iconPack: '',
    iconComponent: 'OrugaIcon',
    statusIcon: false,
    button: {
      override: true,
      rootClass: 'btn',
      variantClass: 'btn-',
    },
    checkbox: {
      checkClass: 'checkbox',
      checkCheckedClass: 'checkbox-checked',
      labelClass: 'checkbox-label',
    },
    radio: {
      override: true,
      rootClass: 'radio',
      labelClass: 'radio-label',
      checkClass: 'radio-check',
      checkedClass: 'radio-checked',
    },
    field: {
      override: true,
      labelClass: 'field-label',
      messageClass: 'text-xs italic',
      variantClass: 'field-',
    },
    input: {
      override: true,
      rootClass: 'input',
      inputClass: 'input-class',
      roundedClass: 'rounded',
      variantClass: 'input-',
      iconRightClass: 'input-icon-right',
    },

    dropdown: {
      menuClass: 'dropdown-menu',
      itemClass: 'dropdown-item',
      itemActiveClass: 'dropdown-item-active',
    },
    loading: {
      overlayClass: 'loading-background',
    },
    icon: {
      override: true,
      spinClass: 'fa-spin',
    },
    modal: {
      override: true,
      rootClass: 'modal',
      contentClass: 'modal-content',
      overlayClass: 'modal-overlay',
    },
    table: {
      override: true,
      tableClass: 'table',
      rootClass: 'table-root',
      thClass: 'table-header',
      tdClass: 'table-cell',
      thSortableClass: 'table-header-sortable',
      thCurrentSortClass: 'table-header-current-sort',
      thPositionClass: 'table-header-position',
    },
    pagination: {
      override: true,
      rootClass: 'pagination',
      listClass: 'pagination-list',
      linkClass: 'pagination-link',
      linkCurrentClass: 'pagination-link-current',
      nextBtnClass: 'pagination-hide',
      prevBtnClass: 'pagination-hide',
    },
  });
};
