<script setup lang="ts">
interface Props {
  modelValue: string
  size: number
}

const props = withDefaults(defineProps<Props>(), {
  size: 8,
});

const emit = defineEmits(['update:modelValue', 'finish']);
const code: string[] = Array(props.size);

function updateCode() {
  emit('update:modelValue', code.join(''));

  if (code.join('').length === props.size)
    emit('finish');
}

let dataFromPaste: string[] | undefined;
const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

function isNumber(event: Event) {
  const keyPressed: string = (event as KeyboardEvent).key;
  if (!keysAllowed.includes(keyPressed))
    event.preventDefault();
  else
    (event.currentTarget as HTMLInputElement).value = '';
}

function handleInput(event: Event) {
  const inputType = (event as InputEvent).inputType;
  let currentActiveElement = event.target as HTMLInputElement;
  if (inputType === 'insertText')
    (currentActiveElement.nextElementSibling as HTMLElement)?.focus();
  if (inputType === 'insertFromPaste' && dataFromPaste) {
    for (const num of dataFromPaste) {
      const id: number = Number.parseInt(currentActiveElement.id.split('_')[1]);
      currentActiveElement.value = num;
      code[id] = num;
      if (currentActiveElement.nextElementSibling) {
        currentActiveElement
          = currentActiveElement.nextElementSibling as HTMLInputElement;
        (currentActiveElement.nextElementSibling as HTMLElement)?.focus();
      }
    }
  }
  updateCode();
}

function handleDelete(event: Event) {
  // keydown event = move to previous element then only delete number
  const value = (event.target as HTMLInputElement).value;
  const currentActiveElement = event.target as HTMLInputElement;
  if (!value)
    (currentActiveElement.previousElementSibling as HTMLElement)?.focus();
}

function onPaste(event: Event) {
  dataFromPaste = (event as ClipboardEvent).clipboardData
    ?.getData('text')
    .trim().replace(/\D/g, '')
    .split('');
  if (dataFromPaste) {
    for (const num of dataFromPaste) {
      if (!keysAllowed.includes(num))
        event.preventDefault();
    }
  }
}
</script>

<template>
  <div class="py-2">
    <input
      v-for="(_, index) in (code)"
      :id="`input_${index}`"
      :key="index"
      v-model="code[index]"
      :class="`h-13 border-r border-y border-gray-300 text-center text-2xl focus:outline-none focus:border-ui-primary text-3xl
        ${props.size === 8 ? ' w-11.8%' : ' w-16.6%'}
        ${index === props.size - 1 ? ' rounded-r-xl fa6-solid' : ''}
        ${index === 0 ? ' rounded-l-xl border-l' : ''}
        ${index === 3 && props.size === 8 ? ' rounded-r-xl border-r mr-4' : ''}
        ${index === 4 && props.size === 8 ? ' rounded-l-xl border-l' : ''}`"
      type="number"
      pattern="\d*"
      maxlength="1"
      @input="handleInput"
      @keypress="isNumber"
      @keydown.delete="handleDelete"
      @keydown.backspace="handleDelete"
      @keydown.left="(($event.target as HTMLInputElement).previousElementSibling as HTMLElement).focus()"
      @keydown.right="(($event.target as HTMLInputElement).nextElementSibling as HTMLElement).focus()"
      @paste="onPaste"
      @keydown.enter="$emit('finish')"
    >
  </div>
</template>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
