import { useGridStore } from '~/stores/grid';
import { useAppStore } from '~/stores/app';
import { useCacheStore } from '~/stores/cache';
import type { apps_payload } from '~/api/generated';
import { AppService } from '~/api/generated';

export function useSaveApps() {
  const grid = useGridStore();
  const appStore = useAppStore();
  const cache = useCacheStore();

  const router = useRouter();

  const isSaveAppsLoading = ref(false);
  const saveAppsError = ref('');

  /** get new data from server and update cache store */
  async function getAppDataFromServer() {
    const appData = await AppService.getApps(true);

    if (appData)
      cache.apps! = appData;
  }

  /** send local data/changes to api */
  async function saveApps() {
    isSaveAppsLoading.value = true;

    AppService.updateAll({
      categories: grid.categories.map((category) => {
        return {
          /** send categoryData object with empty edges */
          categoryData: { ...category.categoryData, edges: {} },
          /** get apps from arrays and remove empty items */
          apps: category.apps
            .map((appArray, index) => appArray.length ? { ...appArray[0], order: index } : null)
            .filter(appArray => appArray) as apps_payload[],
        };
      }),
    })
      .then(() => {
        appStore.toggleAdmin();
        router.push('/');
      })
      .catch(() => {
        /** show error message */
        saveAppsError.value = 'Fehler. Die Änderungen konnten nicht gespeichert werden.';
      })
      .finally(() => {
        isSaveAppsLoading.value = false;
      });
  }

  return {
    isSaveAppsLoading,
    saveAppsError,
    getAppDataFromServer,
    saveApps,
  };
}
