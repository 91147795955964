/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GroupService {

    /**
     * Get groups
     * @param authorization Insert your access token
     * @returns string OK
     * @throws ApiError
     */
    public static ldapGetGroups(
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/groups',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
