<script setup lang="ts">
interface Props {
  modelValue: number
  total: number
  perPage: number
}
const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(['update:modelValue', 'change']);

const page = computed({
  get() {
    return props.modelValue;
  },

  set(value) {
    return emit('update:modelValue', value);
  },
});
</script>

<template>
  <div class="flex flex-row gap-4 pt-2">
    <div class="w-30 flex items-center">
      Seite {{ page }} von {{ Math.ceil(props.total / props.perPage) }}
    </div>
    <div v-if="Math.ceil(props.total / props.perPage) > 1" class="flex flex-row gap-1">
      <div
        v-if="page > 1"
        class="bg-ui-secondary dark:bg-darkui-secondary my-1 h-8 w-8 flex flex cursor-pointer items-center justify-center rounded"
        @click="page--"
      >
        <div i="fa6-solid-chevron-left" class="h-3 w-3" />
      </div>
      <OPagination
        v-model:current="page"
        :total="props.total"
        :per-page="props.perPage"
        :range-after="8"
        :range-before="8"
        icon-prev=""
        icon-next=""
      >
        <template #next />
        <template #previous />
      </OPagination>
      <div
        v-if="page < Math.ceil(props.total / props.perPage)"
        class="bg-ui-secondary dark:bg-darkui-secondary my-1 h-8 w-8 flex flex cursor-pointer items-center justify-center rounded"
        @click="page++"
      >
        <div i="fa6-solid-chevron-right" class="h-3 w-3" />
      </div>
    </div>
  </div>
</template>
