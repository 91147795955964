<script setup lang="ts">
import Draggable from 'vuedraggable';
import { ref } from 'vue';
import { useGridStore } from '~/stores/grid';
import { useEditBubbleModal } from '~/utils/editBubbleModal';
import { useAddBubbleModal } from '~/utils/addBubbleModal';
import type { ent_App } from '~/api/generated';

const props = defineProps<{
  row: number
  column: number
  data: ent_App[]
  editMode: boolean
}>();

const grid = useGridStore();

const { openEditBubbleModal } = useEditBubbleModal();
const { openAddBubbleModal } = useAddBubbleModal();

const isCurrentElementDragged = ref(false);
const isCurrentElementHovered = ref(false);

watch(grid, () => {
  isCurrentElementHovered.value = (
    props.row === grid.hoveredElement.row
    && props.column === grid.hoveredElement.column
  );
});

/** Update current hoveredElement */
function handleMousemove() {
  if (grid.hoveredElement.row !== props.row)
    grid.hoveredElement.row = props.row;
  if (grid.hoveredElement.column !== props.column)
    grid.hoveredElement.column = props.column;
}

/** Reset hoveredElement on mouse leave */
function handleMouseleave() {
  grid.hoveredElement.row = null;
  grid.hoveredElement.column = null;
}

async function setDragStart() {
  isCurrentElementDragged.value = true;
  grid.isAppDragging! = true;
}

async function setDragEnd() {
  isCurrentElementDragged.value = false;
  grid.isAppDragging! = false;
}

/** If true, show css transition */
const loaded = ref(false);

onMounted(async () => {
  /** 1 ms delay to allow the CSS transition to work properly */
  setTimeout(() => {
    loaded.value = true;
  }, 1);
});
</script>

<template>
  <div
    class="h-30 w-25 rounded-md md:h-35 md:w-33"
    :style="`transition-delay:${(props.row * 20) + (props.column * 20)}ms`"
    :class="{
      'bg-[#fff5] shadow-[0_0_10px_#0001] transition-all': loaded && props.editMode,
    }"
    @mousemove="handleMousemove"
    @mouseleave="handleMouseleave"
  >
    <div
      v-if="
        !props.data.length
          && !grid.isAppDragging
          && isCurrentElementHovered
          && props.editMode
      "
      class="h-full w-full flex items-center justify-center"
    >
      <div
        v-tooltip="'App hinzufügen'"
        class="h-10 w-10 flex items-center self-center justify-center rounded-full bg-green-400 text-center anim-scale-95"
        @click="openAddBubbleModal(props.row, props.column)"
      >
        <div class="i-fa6-solid-plus" />
      </div>
    </div>

    <Draggable
      v-if="props.data.length || grid.isAppDragging"
      :list="props.data"
      item-key="id"
      group="apps"
      animation="150"
      ghost-class="ghost"
      class="h-full w-full"
      :disabled="
        (
          grid.isAppDragging // disable if some element is being dragged
          && !!props.data.length // and if current draggable-list is not empty
          && !isCurrentElementDragged // but allow user to drag into previous/start position
        )
          || !props.editMode
      "
      @start="setDragStart"
      @end="setDragEnd"
    >
      <template #item="{ element: app }">
        <div class="h-full w-full flex select-none justify-center">
          <IconBubble
            :app="app as ent_App"
            :edit-mode="props.editMode"
            @click="openEditBubbleModal(app, props.row, props.column)"
          />
        </div>
      </template>
    </Draggable>
  </div>
</template>
