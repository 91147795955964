<script  setup lang="ts">
import { useProgrammatic } from '@oruga-ui/oruga-next';
import AccountPasswordRestrictions from './AccountPasswordRestrictions.vue';
import { useUserStore } from '~/stores/user';

import type { accounts_result } from '~/api/generated';
import { AccountsService } from '~/api/generated';
import type { ApiResponse } from '~/utils/ApiResponse';

defineEmits<{
  (e: 'close'): void
}>();

const password = ref<string>('');
const passwordConfirmation = ref<string>('');
const showMessage = ref<boolean>(false);
const showErrorMessage = ref<boolean>(false);
const message = ref<string>('');
const isSecure = ref<boolean>(false);

const isLoading = ref<boolean>(false);

const userStore = useUserStore();

const oruga = useProgrammatic();

function openPasswordRestrictionsModal() {
  oruga.oruga.modal.open({
    component: AccountPasswordRestrictions,
    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',

  });
}

const buttonEnabled = computed(() => {
  return isSecure.value && (password.value !== '') && (password.value === passwordConfirmation.value);
});

const changePasswordData = ref<ApiResponse<accounts_result>>({ data: undefined, status: undefined });

async function setPassword() {
  if (buttonEnabled.value) {
    isLoading.value = true;

    AccountsService.changePassword({
      newPassword: password.value,
    })
      .then(result => changePasswordData.value = { data: result, status: 'success' })
      .catch(() => changePasswordData.value = { data: undefined, status: 'error' });
  }
}

watch(changePasswordData, () => {
  const data = changePasswordData.value.data;
  isLoading.value = false;

  if (data?.success) {
    showMessage.value = true;
    showErrorMessage.value = false;
    userStore.setPasswordSet(true);
  }
  else {
    showErrorMessage.value = true;
    message.value = data?.message || '';
  }

  if (changePasswordData.value.status === 'error') {
    showErrorMessage.value = true;
    message.value = data?.message || '';
  }
});
</script>

<template>
  <BaseModal
    title="Passwort ändern "
    @submit="setPassword"
    @close="$emit('close')"
  >
    <template #body>
      <template v-if="isLoading">
        <div class="flex justify-center">
          <div class="flex items-center justify-center">
            <div i="fa6-solid-spinner" class="m-4 h-8 w-8 animate-spin" />
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="!showMessage">
          <o-field label="Neues Passwort">
            <o-input
              v-model="password"
              password-reveal
              type="password"
            />
          </o-field>
          <o-field label="Neues Passwort bestätigen">
            <o-input
              v-model="passwordConfirmation"
              password-reveal
              type="password"
            />
          </o-field>
          <div class="pt-4">
            <PasswordCheck v-model="isSecure" :password="password" :username="userStore.user?.username" />
          </div>
          <div class="flex justify-between gap-4 pt-4">
            <a href="https://www.gaijin.at/de/tools/password-generator" target="_blank">Passwortgenerator</a>
            <a href="#" @click="openPasswordRestrictionsModal">Hinweise zur Komplexität</a>
          </div>
        </template>
        <div
          v-if="showMessage"
          class="alert alert-success"
        >
          Das Passwort wurde erfolgreich gesetzt.
        </div>
        <div
          v-if="showErrorMessage"
          class="alert alert-danger"
        >
          Das Passwort konnte nicht gesetzt werden. Bitte probieren Sie es noch einmal.
        </div>
      </template>
    </template>
    <template #footer>
      <div
        v-if="!showMessage && !isLoading"
        class="flex justify-end"
      >
        <o-button
          :disabled="!buttonEnabled"
          native-type="submit"
        >
          Neues Passwort setzen
        </o-button>
      </div>
      <div v-else-if="!isLoading">
        <div class="flex justify-end">
          <o-button
            @click="$emit('close')"
          >
            Okay
          </o-button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
