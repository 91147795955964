<script  setup lang="ts">
import type { ent_AppGroup } from '~/api/generated';

interface Props {
  group: ent_AppGroup
  title: string
  containsApps?: boolean // true if category contains apps
  isLastCategory?: boolean // true if grid contains only one category
}
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'close'): void
  (e: 'delete', result: ent_AppGroup): void
  (e: 'save', result: ent_AppGroup): void
}>();
const title = ref<string>(props.group.title as string);
const url = ref<string>(props.group.url as string);
const iconName = ref<string>(props.group.iconName as string);
const groups = ref<string[]>(props.group.groups !== '' && props.group.groups !== undefined
  ? (props.group.groups as string).split(',') as string[]
  : []);

function deleteCategory() {
  emit('delete', props.group);
  emit('close');
}

function saveCategory() {
  const result = Object.assign({}, props.group);
  result.title = title.value;
  result.url = url.value;
  result.iconName = iconName.value;
  result.groups = groups.value.join(',');

  emit('save', result);
  emit('close');
}
</script>

<template>
  <BaseModal
    :title="props.title"
    @submit="saveCategory()"
    @close="$emit('close')"
  >
    <template #body>
      <div class="flex flex-row items-center gap-4">
        <div class="w-full">
          <o-field label="Titel">
            <o-input
              v-model="title"
              required
            />
          </o-field>
          <o-field label="URL">
            <o-input
              v-model="url"
              :required="false"
            />
          </o-field>
          <o-field label="Icon">
            <IconPicker v-model="iconName" />
          </o-field>
          <o-field label="Zugriffsberechtigung">
            <GroupsPicker v-model="groups" />
          </o-field>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex justify-between gap-4"
      >
        <o-button
          @click="$emit('close')"
        >
          Abbrechen
        </o-button>
        <div class="flex flex-row gap-4">
          <div
            v-tooltip="props.isLastCategory
              ? 'Die letzte Kategorie kann nicht gelöscht werden'
              : props.containsApps
                ? 'Kategorie darf keine Apps enthalten'
                : null
            "
          >
            <o-button
              v-if="props.title === 'Kategorie bearbeiten'"
              :disabled="props.containsApps || props.isLastCategory"
              variant="danger"
              @click="deleteCategory()"
            >
              Löschen
            </o-button>
          </div>
          <o-button
            variant="success"
            native-type="submit"
          >
            Speichern
          </o-button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
