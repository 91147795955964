<script  setup lang="ts">
import { useProgrammatic } from '@oruga-ui/oruga-next';
import AccountPasswordRestrictions from './AccountPasswordRestrictions.vue';
import type { users_User, users_updateUser } from '~/api/generated';
import { UserService } from '~/api/generated';
import type { ApiResponse } from '~/utils/ApiResponse';

interface Props {
  user: users_User
}
const props = defineProps<Props>();

defineEmits<{
  (e: 'close'): void
}>();

const showMessage = ref<boolean>(false);
const showErrorMessage = ref<boolean>(false);
const checkboxOwnPassword = ref<boolean>(false);
const ownPassword = ref<string>('');
const isSecure = ref<boolean>(false);

const isLoading = ref<boolean>(false);

function generatePassword() {
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const special = '#+*~#&%$!';

  const randomString = [];

  for (let i = 0; i < 6; i++) {
    const num = Math.floor(Math.random() * chars.length);
    randomString.push(chars[num]);
  }
  const upper = Math.floor(Math.random() * randomString.length);
  randomString[upper] = randomString[upper].toUpperCase();

  for (let i = 0; i < 2; i++) {
    const num = Math.floor(Math.random() * numbers.length);
    randomString.push(numbers[num]);
  }
  for (let i = 0; i < 1; i++) {
    const num = Math.floor(Math.random() * special.length);
    randomString.push(special[num]);
  }

  return randomString.join('');
}

const ldapUpdateUserData = ref<ApiResponse<string>>({ data: undefined, status: undefined });

async function changePassword() {
  if (!checkboxOwnPassword.value)
    ownPassword.value = generatePassword();

  isLoading.value = true;

  const user: users_updateUser = { password: ownPassword.value };

  UserService.ldapUpdateUser(props.user.account_id as string, user)
    .then(result => ldapUpdateUserData.value = { data: result, status: 'success' })
    .catch(() => ldapUpdateUserData.value = { data: undefined, status: 'error' });
}

watch(ldapUpdateUserData, () => {
  const status = ldapUpdateUserData.value.status;

  isLoading.value = false;

  if (status === 'success') {
    showMessage.value = true;
    showErrorMessage.value = false;
  }
  else if (status === 'error') {
    showErrorMessage.value = true;
  }
});

const oruga = useProgrammatic();

function openPasswordRestrictionsModal() {
  oruga.oruga.modal.open({
    component: AccountPasswordRestrictions,
    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',

  });
}
</script>

<template>
  <BaseModal
    title="Passwort ändern"
    @submit="changePassword"
    @close="$emit('close')"
  >
    <template #body>
      <template v-if="isLoading">
        <div class="flex justify-center">
          <div class="flex items-center justify-center">
            <div i="fa6-solid-spinner" class="m-4 h-8 w-8 animate-spin" />
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="!showMessage">
          <div class="flex flex-col gap-2">
            <div class="alert alert-info">
              Möchten Sie das Passwort von <strong>{{ props.user.name }} ({{ props.user.email }})</strong> wirklich ändern? <br>
              Die Änderung wird sofort durchgeführt!
            </div>
            <div class="text-right">
              <o-checkbox v-model="checkboxOwnPassword">
                Eigenes Passwort vergeben
              </o-checkbox>
            </div>
          </div>
          <div v-if="checkboxOwnPassword">
            <o-field label="Passwort">
              <o-input
                v-model="ownPassword"
                password-reveal
                type="password"
              />
            </o-field>
            <div class="pt-4">
              <PasswordCheck v-model="isSecure" :password="ownPassword" :username="props.user.name" />
            </div>
            <div class="flex justify-evenly gap-4 pt-2">
              <a href="https://www.gaijin.at/de/tools/password-generator" target="_blank">Passwortgenerator</a>
              <a href="#" @click="openPasswordRestrictionsModal">Hinweise zur Komplexität</a>
            </div>
          </div>
        </template>
        <div
          v-if="showMessage"
        >
          <div class="alert alert-success">
            Das Passwort von
            <strong>{{ props.user.username }}</strong> (<strong>{{ props.user.email }}</strong>)
            wurde erfolgreich geändert.<br>
            Bitte teilen Sie dem Anwender das folgende Passwort mit.
          </div>
          <div class="flex justify-center pt-5">
            <div class="rounded bg-dark-600 px-3 py-1 text-4xl text-light-200">
              {{ ownPassword }}
            </div>
          </div>
        </div>
        <div v-if="showErrorMessage" class="pt-4">
          <div class="alert alert-danger">
            Fehler beim Ändern des Passworts. Bitte probieren Sie es noch einmal.
          </div>
        </div>
      </template>
    </template>
    <template #footer>
      <template v-if="!isLoading">
        <div
          v-if="!showMessage"
          class="flex justify-between gap-4"
        >
          <o-button
            @click="$emit('close')"
          >
            Abbrechen
          </o-button>
          <o-button
            native-type="submit"
            :disabled="!isSecure && checkboxOwnPassword"
          >
            Neues Passwort vergeben
          </o-button>
        </div>
        <div
          v-else
          class="flex justify-center gap-4"
        >
          <o-button variant="success" @click="$emit('close')">
            Ok
          </o-button>
        </div>
      </template>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
