<script setup lang="ts">
interface Props {
  icon: Array<string>
}
const props = withDefaults(defineProps<Props>(), {});

const icon = computed(() => {
  if (props.icon[1] === 'eye')
    return 'fa6-solid-eye';
  if (props.icon[1] === 'eye-off')
    return 'fa6-solid-eye-slash';
  if (props.icon[1] === 'close')
    return '';
  if (props.icon[1] === 'arrow-up')
    return 'fa6-solid-arrow-up';
  else
    return props.icon[1];
},
);
</script>

<template>
  <div
    v-if="icon !== ''"
    :class="`h-full self-center
    flex items-center justify-center text-center
    ${props.icon[1] !== 'arrow-up' ? ' px-2 bg-gray-200 text-gray-500' : ''}
    ${props.icon[1] === 'eye' || props.icon[1] === 'eye-off' ? ' rounded-r-lg' : ' rounded-l-lg'}`"
  >
    <div :class="`i-${icon}`" />
  </div>
</template>
