<script  setup lang="ts">
import { useRouter } from 'vue-router';
import { useAppStore } from '~/stores/app';

const emit = defineEmits(['close']);
const app = useAppStore();
const router = useRouter();

watch(() => app.idleTimer, () => {
  if (app.idleTimer > 60)
    emit('close');
});
</script>

<template>
  <BaseModal
    :title="app.idleTimer === 0 ? 'Sie wurden automatisch augeloggt!' : 'Sie werden in Kürze automatisch ausgeloggt.'"
    :hide-footer="true"
  >
    <template #body>
      <div v-if="app.idleTimer !== 0" class="flex flex-col items-center justify-center">
        <div class="text-4xl">
          {{ app.idleTimer }}
        </div>
        <div class="text-xl">
          Sekunden
        </div>

        <div class="pt-5 text-sm text-gray-500">
          Um die Abmeldung zu verhindern, drücken Sie eine beliebige Taste.
        </div>
      </div>
      <div v-else class="flex flex-col items-center justify-center">
        Sie wurden nach {{ Math.round(app.maxIdleTime / 60) }} Minuten Inaktivität automatisch ausgeloggt.
        <o-button
          class="mt-5"
          variant="success"
          @click="router.push('/login')"
        >
          Erneut anmelden
        </o-button>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
