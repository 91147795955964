import { Dropdown, Menu, Tooltip, VTooltip } from 'floating-vue';

import type { UserModule } from '~/types';
import 'floating-vue/dist/style.css';

export const install: UserModule = ({ app }) => {
  app.directive('tooltip', VTooltip);
  app.component('VMenu', Menu);
  app.component('VDropdown', Dropdown);
  app.component('VTooltip', Tooltip);
};
