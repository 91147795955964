<script setup lang="ts">
import { AppService } from '~/api/generated';
import type { ent_AppType } from '~/api/generated';

const props = defineProps<{
  modelValue: (number)
}>();

const emit = defineEmits(['update:modelValue', 'appTypeChanged']);

const appNames = ref<string[]>([]);
const appTypes = ref<ent_AppType[]>([]);

const selectedApp = ref<string>('');

// Guacamole
const guacamoleServer = ref<string>('');
const guacamoleServers = ref<string[]>([]);

function changeApp(app: string) {
  selectedApp.value = app;
  if (app === 'Link')
    emit('update:modelValue', 0);
  if (app === 'Virtuelles Klassenbuch')
    emit('update:modelValue', appTypes.value.find(appType => appType.name === 'Virtuelles Klassenbuch')?.id);
  if (app === 'Virtueller Stundenplan')
    emit('update:modelValue', appTypes.value.find(appType => appType.name === 'Virtueller Stundenplan')?.id);
  if (app === 'E-Mail')
    emit('update:modelValue', appTypes.value.find(appType => appType.name === 'E-Mail')?.id);
  if (app === 'Guacamole')
    emit('update:modelValue', -1);

  emit('appTypeChanged', app);
}

function changeGuacamoleApp(app: string) {
  appTypes.value.forEach((appType) => {
    if (appType.name === 'Guacamole') {
      const json = JSON.parse(appType.config as string);
      if (json.Name === app)
        emit('update:modelValue', appType.id);
    }
  });
}

onMounted(async () => {
  AppService.getAppsTypes()
    .then((result) => {
      appTypes.value = result;
      appNames.value = ['Link', ...new Set(result.map(appType => appType.name as string))];

      guacamoleServers.value = [];
      result.forEach((appType) => {
        if (appType.id === props.modelValue) {
          selectedApp.value = appType.name as string;
          if (appType.name === 'Guacamole') {
            const json = JSON.parse(appType.config as string);
            guacamoleServer.value = json.Name;
            emit('appTypeChanged', 'Guacamole');
          }
        }

        if (appType.name === 'Guacamole') {
          const json = JSON.parse(appType.config as string);
          guacamoleServers.value.push(json.Name);
        }
      });

      if (selectedApp.value === '')
        selectedApp.value = 'Link';
    },
    )
    .catch(() => appTypes.value = []);
});
</script>

<template>
  <div class="flex flex-row gap-2">
    <o-dropdown
      v-model="selectedApp"
      :scrollable="true"
      :max-height="200"
      @change="changeApp($event)"
    >
      <template #trigger>
        <o-input
          v-model="selectedApp"
          readonly
          class="cursor-pointer"
        >
          {{ selectedApp }}
        </o-input>
      </template>

      <o-dropdown-item
        v-for="(name, index) in appNames"
        :key="index"
        :value="name"
        aria-role="listitem"
      >
        <div>
          {{ name }}
        </div>
      </o-dropdown-item>
    </o-dropdown>
    <div v-if="selectedApp === 'Guacamole'" class="flex flex-row">
      <o-dropdown
        v-model="guacamoleServer"
        :scrollable="true"
        :max-height="200"
        @change="changeGuacamoleApp($event)"
      >
        <template #trigger>
          <o-input
            v-model="guacamoleServer"
            class="cursor-pointer"
            readonly
            placeholder="Bitte Server auswählen"
          >
            {{ guacamoleServer }}
          </o-input>
        </template>

        <o-dropdown-item
          v-for="(name, index) in guacamoleServers"
          :key="index"
          :value="name"
          aria-role="listitem"
        >
          <div>
            {{ name }}
          </div>
        </o-dropdown-item>
      </o-dropdown>
    </div>
  </div>
</template>
