import { setupLayouts } from 'virtual:generated-layouts';
import { createRouter, createWebHistory } from 'vue-router';
import clickOutside from './directives/click-outside';
import App from './App.vue';
import generatedRoutes from '~pages';

import '@unocss/reset/tailwind.css';
import './styles/main.css';
import 'uno.css';
import { OpenAPI } from '~/api/generated';

const app = createApp(App);

// setup up pages with layouts
const routes = setupLayouts(generatedRoutes);
const router = createRouter({ history: createWebHistory(), routes });
app.use(router);
app.directive('click-outside', clickOutside);

// overwrite default base url
OpenAPI.BASE = '';

// install all modules under `modules/`
Object.values(import.meta.globEager('./modules/*.ts')).map((i: any) => i.install?.({ app, router, routes }));

app.mount('#app');
