import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', () => {
  const router = useRouter();

  const adminMode = ref<boolean>(false);
  const iframeMode = ref<boolean>(false);
  const iframeURL = ref<string>('');
  const timer = ref(0);

  const maxIdleTime = Number.parseInt(import.meta.env.VITE_LOGOUT_ON_IDLE_TIME as string, 10) ?? 0;

  const idleTimer = ref(maxIdleTime);
  const pauseTimer = ref(false);
  const lastActivity = ref(Date.now());

  const showFullscreenModeButton = ref<boolean>(false);

  function toggleAdmin() {
    adminMode.value = !adminMode.value;
  }

  function stopIframeMode(redirect = '/') {
    pauseTimer.value = false;
    iframeMode.value = false;
    adminMode.value = false;
    iframeURL.value = '';
    showFullscreenModeButton.value = false;
    router.push(redirect);
  }

  function resetIdleTimer() {
    idleTimer.value = maxIdleTime;
    lastActivity.value = Date.now();
  }

  return {
    stopIframeMode,
    toggleAdmin,
    resetIdleTimer,
    adminMode,
    iframeMode,
    iframeURL,
    showFullscreenModeButton,
    maxIdleTime,
    idleTimer,
    pauseTimer,
    timer,
    lastActivity,
  };
});
