<script  setup lang="ts">

</script>

<template>
  <BaseModal
    title="Hinweise zur Komplexität"
    :hide-footer="true"
  >
    <template #body>
      <ul class="list-disc list-outside px-8">
        <li>
          Das Kennwort darf nicht den Kontonamen des Benutzers oder mehr als drei Zeichen enthalten,
          die nacheinander im vollständigen Namen des Benutzers vorkommen.
        </li>
        <li>Das Kennwort muss mindestens neun Zeichen lang sein.</li>
        <li>Das Kennwort muss Zeichen aus vier der folgenden Kategorien enthalten:</li>
        <div>
          <ul class="list-disc list-outside px-12">
            <li>Großbuchstaben (A bis Z)</li>
            <li>Kleinbuchstaben (a bis z)</li>
            <li>Zahlen zur Basis 10 (0 bis 9)</li>
            <li>Nicht alphabetische Zeichen (zum Beispiel !, $, #, %)</li>
          </ul>
        </div>
      </ul>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
