import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import type { UserModule } from '~/types';
import { OpenAPI } from '~/api/generated';
import { useUserStore } from '~/stores/user';

// Setup Pinia
// https://pinia.esm.dev/
export const install: UserModule = ({ app }) => {
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);
  app.use(pinia);

  // set auth token
  OpenAPI.TOKEN = async () => {
    const userStore = useUserStore();
    return await userStore.getToken()
      .catch(() => {
        // set empty string on fail (e.g. user is not signed in)
        return '';
      });
  };
};
