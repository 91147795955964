<script setup lang="ts">
import { useAppStore } from '~/stores/app';
import { useUserStore } from '~/stores/user';
import { useLogoutTimerModal } from '~/utils/logoutTimerModal';

const app = useAppStore();
const user = useUserStore();
const router = useRouter();
const { openLogoutTimerModal } = useLogoutTimerModal();

window.onpopstate = function () {
  app.stopIframeMode();
};

function toggleFullscreen() {
  const frame = document.querySelector<HTMLIFrameElement>('#frame');

  if (document.fullscreenElement) {
    document.exitFullscreen();
    frame?.focus();
  }
  else {
    frame?.requestFullscreen();
    frame?.focus();
  }
};

if (app.maxIdleTime > 0) {
  clearTimeout(app.timer);
  app.timer = window.setInterval(() => {
    if (!app.pauseTimer && user.isAuthenticated) {
      if (app.idleTimer > 0)
        app.idleTimer--;

      else
        router.push('/logout');
    }
    else {
      app.lastActivity = Date.now();
    }
  }, 1000);

  // reset on mousemove or keypress
  window.addEventListener('click', app.resetIdleTimer);
  window.addEventListener('keypress', app.resetIdleTimer);
}

// watch idletimer
watch(() => app.idleTimer, () => {
  if (app.idleTimer === 60)
    openLogoutTimerModal();
  // last activity > maxidletime
  if (Date.now() - app.lastActivity > app.maxIdleTime * 1000) {
    openLogoutTimerModal();
    app.idleTimer = 0;
  }
});
</script>

<template>
  <div :class="`flex flex-row h-10 justify-between gap-8 ${app.iframeMode ? 'border-b-1 border-light-primary' : ''}` ">
    <img src="/images/logo.png" alt="Logo" :class="`cursor-pointer ${app.iframeMode ? 'h-8 m-1' : 'h-10'}`" @click="app.stopIframeMode()">
    <a v-if="!user.isAuthenticated" @click="router.push('/login')">
      <o-button class="px-2">
        <div class="flex flex-row gap-2">
          <div class="i-fa6-solid-right-to-bracket self-center" />
          Login
        </div>
      </o-button>
    </a>
    <div v-if="app.adminMode" class="self-center text-lg sm:text-2xl">
      Editiermodus
    </div>
    <div v-if="user.isAuthenticated" class="flex">
      <o-button v-if="app.iframeMode" variant="success" class="m-1 text-sm" @click="app.stopIframeMode()">
        Zurück zum Portal
      </o-button>

      <o-button v-if="app.showFullscreenModeButton" v-tooltip="'Wechsel zum Vollbildmodus'" class="m-1 px-2 text-sm" @click="toggleFullscreen()">
        <div class="m-0 flex flex">
          <div class="i-fa6-solid-desktop self-center" />
        </div>
      </o-button>

      <PortalMenu class="ml-4" :size="app.iframeMode ? 'small' : 'normal'" />
    </div>
  </div>
</template>
