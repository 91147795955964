import { useProgrammatic } from '@oruga-ui/oruga-next';
import AdminEditCategory from '~/components/modals/AdminEditCategory.vue';
import { useGridStore } from '~/stores/grid';
import type { ent_AppGroup } from '~/api/generated';

export function useEditCategoryModal() {
  const oruga = useProgrammatic();
  const grid = useGridStore();

  const editCategoryModal = (group: ent_AppGroup, categoryIndex: number) => {
    const appsInCategory = grid.categories[categoryIndex].apps.flatMap(app => app);
    const isLastCategory = grid.categories.length === 1;

    oruga.oruga.modal.open({
      component: AdminEditCategory,
      props: {
        group,
        title: 'Kategorie bearbeiten',
        containsApps: !!appsInCategory.length,
        isLastCategory,
      },
      hasModalCard: true,
      trapFocus: true,
      scroll: 'keep',
      animation: 'fade',
      events: {
        save: (result: ent_AppGroup) => {
          /** Save new data to grid */
          grid.categories[categoryIndex].categoryData = result;
        },
        delete: () => {
          /** Remove category from grid */
          grid.categories.splice(categoryIndex, 1);
        },
      },
    });
  };

  return { editCategoryModal };
}
