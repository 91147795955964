/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { accounts_changePassword } from '../models/accounts_changePassword';
import type { accounts_checkCodePayload } from '../models/accounts_checkCodePayload';
import type { accounts_generateCodePayload } from '../models/accounts_generateCodePayload';
import type { accounts_resetPasswordPayload } from '../models/accounts_resetPasswordPayload';
import type { accounts_result } from '../models/accounts_result';
import type { accounts_updateUser } from '../models/accounts_updateUser';
import type { accounts_usernameResponse } from '../models/accounts_usernameResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountsService {

    /**
     * Sends a reset code to the users private mail address
     * @param account Account
     * @returns accounts_usernameResponse OK
     * @throws ApiError
     */
    public static passwordCreateResetCode(
        account: accounts_generateCodePayload,
    ): CancelablePromise<accounts_usernameResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/reset-code',
            body: account,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Checks a reset code
     * @param code Check Code
     * @returns string ok
     * @throws ApiError
     */
    public static checkResetCode(
        code: accounts_checkCodePayload,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/reset-code/check',
            body: code,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Change password with reset code
     * @param resetPassword Change Password
     * @returns string ok
     * @throws ApiError
     */
    public static resetChangePassword(
        resetPassword: accounts_resetPasswordPayload,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/reset-code/reset',
            body: resetPassword,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Update Account
     * @param account Update user
     * @param authorization Insert your access token
     * @returns any OK
     * @throws ApiError
     */
    public static updateAccount(
        account: accounts_updateUser,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/accounts',
            headers: {
                'Authorization': authorization,
            },
            body: account,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Allows the user to change their password
     * @param account Change Password
     * @param authorization Insert your access token
     * @returns accounts_result OK
     * @throws ApiError
     */
    public static changePassword(
        account: accounts_changePassword,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<accounts_result> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/password_changes',
            headers: {
                'Authorization': authorization,
            },
            body: account,
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
