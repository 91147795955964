import { useProgrammatic } from '@oruga-ui/oruga-next';
import AdminEditCategory from '~/components/modals/AdminEditCategory.vue';
import { useGridStore } from '~/stores/grid';
import { useBuildGrid } from '~/utils/buildGrid';
import type { ent_App, ent_AppGroup } from '~/api/generated';

export function useAddCategoryModal() {
  const oruga = useProgrammatic();
  const grid = useGridStore();
  const { buildEmptyRow } = useBuildGrid();

  const openAddCategoryModal = () => {
    oruga.oruga.modal.open({
      component: AdminEditCategory,
      props: {
        group: {
          title: 'Titel',
          iconName: 'i-fa6-solid-folder',
          url: import.meta.env.VITE_URL,
          groups: '',
          edges: {
            apps: [] as Array<ent_App>,
          },
        } as ent_AppGroup,
        title: 'Kategorie hinzufügen',
      },
      hasModalCard: true,
      trapFocus: true,
      scroll: 'keep',
      animation: 'fade',
      events: {
        save: (result: ent_AppGroup) => {
          const findCurrentRowLength = grid.categories[0]?.apps.length;
          const emptyRow = buildEmptyRow(findCurrentRowLength || grid.numberOfColumns, true);

          /** Save new row to grid */
          grid.categories.push({
            categoryData: result,
            apps: emptyRow.apps,
          });
        },
      },
    });
  };

  return { openAddCategoryModal };
}
