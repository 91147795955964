<script setup lang="ts">
import { GroupService } from '~/api/generated';
import type { ApiResponse } from '~/utils/ApiResponse';

const props = defineProps<{
  modelValue: string[]
}>();

const emit = defineEmits(['update:modelValue']);

const showGroupsPicker = ref<boolean>(false);
const groups = ref<Array<string>>([]);
const selectedGroups = ref<Array<string>>([]);

const mode = ref<string>('');

if (props.modelValue.length > 0) {
  if (props.modelValue[0] === 'All' || props.modelValue[0] === 'LoggedIn' || props.modelValue[0] === 'LoggedOut') {
    mode.value = props.modelValue[0];
  }
  else {
    mode.value = 'Groups';
    selectedGroups.value = props.modelValue;
  }
}

const buttonEnabled = computed(() => {
  return groups.value.filter(g => !props.modelValue.includes(g)).length > 0;
});

function update() {
  let result: string[] = [];
  if (mode.value !== 'Groups')
    result.push(mode.value as string);
  else
    result = [...selectedGroups.value];

  emit('update:modelValue', result);
}

function updateGroups(group: string) {
  selectedGroups.value = [...selectedGroups.value, group];
  showGroupsPicker.value = false;
  update();
}

function removeGroup(group: string) {
  selectedGroups.value = selectedGroups.value.filter(g => g !== group);
  update();
}

const ldapGetGroupData = ref<ApiResponse<string[]>>({ data: undefined, status: undefined });

onMounted(async () => {
  GroupService.ldapGetGroups()
    .then(result => ldapGetGroupData.value = { data: result, status: 'success' })
    .catch(() => ldapGetGroupData.value = { data: undefined, status: 'error' });
});

watch(ldapGetGroupData, () => {
  if (ldapGetGroupData.value.status === 'success') {
    groups.value = ldapGetGroupData.value.data || [];
    // filter groups already in modelValue
    groups.value = groups.value.filter(g => !props.modelValue.includes(g));
  }
});
</script>

<template>
  <div class="flex flex-col justify-between">
    <o-radio v-model="mode" name="name" native-value="All" @update:model-value="update()">
      Jeder
    </o-radio>
    <o-radio v-model="mode" name="name" native-value="LoggedIn" @update:model-value="update()">
      Nur eingeloggte Benutzer
    </o-radio>
    <o-radio v-model="mode" name="name" native-value="LoggedOut" @update:model-value="update()">
      Nur ausgeloggte Benutzer
    </o-radio>
    <o-radio v-model="mode" name="name" native-value="Groups" @update:model-value="update()">
      Nur bestimmte Gruppen
    </o-radio>
  </div>
  <div v-if="mode === 'Groups'" class="flex flex-col justify-between gap-1">
    <div v-if="selectedGroups.length > 0" class="flex flex-row flex-wrap gap-1">
      <div
        v-for="group in selectedGroups" :key="group"
        class="flex flex-row items-center justify-center gap-1 rounded bg-light-primary px-2 text-center text-light"
      >
        {{ group }}
        <div class="i-fa6-solid-xmark cursor-pointer hover:bg-red" @click="removeGroup(group)" />
      </div>
    </div>
    <div v-else class="p-1">
      Jeder
    </div>
    <div>
      <VDropdown placement="bottom-start" :arrow-padding="20">
        <o-button :disabled="!buttonEnabled">
          Gruppe Hinzufügen
        </o-button>
        <template #popper="{ hide }">
          <div
            v-if="buttonEnabled"
            class="z-10 max-h-xs flex flex-col overflow-y-scroll border border-gray-200 rounded bg-white drop-shadow-xl"
          >
            <div
              v-for="(group) in groups.filter(g => !selectedGroups.includes(g))" :key="group"
              class="m-1 cursor-pointer px2 hover-bg-light-primary hover-text-light" @click="hide(); updateGroups(group)"
            >
              {{ group }}
            </div>
          </div>
        </template>
      </VDropdown>
    </div>
  </div>
</template>
