<script  setup lang="ts">
import { LoginService } from '~/api/generated';
import type { login_codePayload } from '~/api/generated';
import { useUserStore } from '~/stores/user';

defineEmits(['close']);

const user = useUserStore();

const force2faTeachers = import.meta.env.VITE_FORCE_2FA_TEACHERS?.toLowerCase() === 'true' && user.user?.type === 'L';

const qrCode = ref<string>();
const verificationCode = ref<string>('');
const showHelpText = ref<boolean>(false);

const errorMessage = ref<string>();
const message = ref<string>();

const twoFactorActive = ref<boolean>(user.user?.secondFactorSet as boolean);

GetCode();

async function GetCode() {
  LoginService.generateTotpCode()
    .then((code) => {
      qrCode.value = code as string;
    },
    ).catch(() => {
      errorMessage.value = `Code Authentifizierungscode nicht generiert werden!`;
    });
}

async function VerifyCode() {
  const codeData: login_codePayload = { code: verificationCode.value as string };
  LoginService.verifyTotpCode(codeData)
    .then(() => {
      errorMessage.value = '';
      user.user!.secondFactorSet = true;
      message.value = 'Die Zwei-Faktor-Authentifizierung wurde erfolgreich eingerichtet.';
    },
    ).catch(() => {
      errorMessage.value = `Der Authentifizierungscode konnte nicht verifiziert werden!`;
    });
}

async function deleteCode() {
  LoginService.deleteTotpCode()
    .then(() => {
      errorMessage.value = '';
      user.user!.secondFactorSet = false;
      message.value = 'Die Zwei-Faktor-Authentifizierung wurde erfolgreich deaktiviert.';
    },
    ).catch(() => {
      errorMessage.value = `Der Authentifizierungscode konnte nicht gelöscht werden!`;
    });
}
</script>

<template>
  <BaseModal
    title="Konfiguration der Zwei-Faktor-Authentifizierung"
    @submit="VerifyCode()"
    @close="$emit('close')"
  >
    <template #body>
      <template v-if="message">
        {{ message }}
      </template>
      <template v-else>
        <template v-if="twoFactorActive">
          Die Zwei-Faktor-Authentifizierung wurde bereits erfolgreich aktiviert.
        </template>
        <template v-else>
          <template v-if="showHelpText">
            <b>Schritt 1: QR-Code scannen</b><br>

            <div>
              Laden Sie eine Authenticator-App, wie beispielsweise den <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=de" target="_blank">Google Authenticator</a>, auf Ihr Mobilgerät herunter.
              Öffnen Sie die Authenticator-App und fügen Sie ein neues Konto hinzu, indem Sie den QR-Code scannen.
            </div><br>
            <b>Schritt 2: Authentifizierungscode eingeben</b><br>
            <div>
              Nach dem Scannen des QR-Codes zeigt die Authenticator-App ein neues Konto an.
              Dieses Konto in der App wird fortlaufend einen sich ändernden Authentifizierungscode generieren.
              Tragen Sie den aktuellen Code ein, der in der Authenticator-App angezeigt wird.
            </div><br>

            <b>Schritt 3: Abschließen der Einrichtung</b><br>

            Nach erfolgreicher Eingabe des Codes bestätigen Sie die Aktivierung der Zwei-Faktor-Authentifizierung.
            Ab diesem Zeitpunkt wird bei jedem Login ein neuer Authentifizierungscode benötigt, der von der Authenticator-App erzeugt wird.
          </template>
          <template v-else>
            <div v-if="force2faTeachers">
              Die Zwei-Faktor-Authentifizierung ist für Lehrer*innen verpflichtend.
            </div>
            <a class="cursor-pointer" @click="showHelpText = true ">Wie richte ich die Zwei-Faktor-Authentifizierung ein? </a>
            <br><br>
            <div class="flex flex-row items-center gap-4">
              <div class="w-full">
                <div class="flex flex-col items-center self-center justify-center">
                  <img v-if="qrCode" class="w-70%" :src="`data:image/png;base64,${qrCode}`">

                  <o-field label="Authentifizierungscode" class="w-70% pt-5">
                    <CodeInput v-model="verificationCode" :size="6" @finish="VerifyCode()" />
                  </o-field>
                </div>
                <div class="text-sm color-red">
                  {{ errorMessage }}
                </div>
              </div>
            </div>
          </template>
        </template>
      </template>
    </template>
    <template #footer>
      <div
        class="flex justify-right gap-4"
      >
        <o-button v-if="message" variant="success" @click="$emit('close')">
          Okay
        </o-button>
        <template v-else>
          <template v-if="twoFactorActive">
            <div class="flex gap-2">
              <o-button
                @click="twoFactorActive = false"
              >
                Neues Gerät anmelden
              </o-button>
              <o-button
                v-if="!force2faTeachers"
                variant="danger"
                @click="deleteCode()"
              >
                Deaktivieren
              </o-button>
            </div>
          </template>
          <template v-else>
            <o-button
              v-if="showHelpText"
              @click="showHelpText = false"
            >
              Zwei-Faktor-Authentifizierung einrichten
            </o-button>
            <o-button
              v-else
              variant="success"
              native-type="submit"
            >
              Absenden
            </o-button>
          </template>
        </template>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
