import type { GridRow } from '~/stores/grid';
import { useGridStore } from '~/stores/grid';
import type { ent_AppGroup } from '~/api/generated';

export function useBuildGrid() {
  const grid = useGridStore();

  /** Build empty row */
  function buildEmptyRow(highestColumnNumber: number, fullWidth: boolean) {
    const gridRow: GridRow = { apps: [] };

    /** Fill rows with empty items */
    const safeNumberOfColumns = Math.max(
      highestColumnNumber,
      fullWidth ? grid.numberOfColumns : 0, // Optionally show at least n columns
    );
    for (let i = 0; i < safeNumberOfColumns; i++)
      gridRow.apps.push([]);
    return gridRow;
  }

  /** Build empty grid + fill with apps from db */
  function buildGrid(apps: ent_AppGroup[], editMode: boolean) {
    const allCategoryOrders = apps.map(category => category.order || 0) || [];
    const findHighestRowNumber = Math.max(...allCategoryOrders) + 1;

    /** Reset grid */
    grid.categories! = [];

    /** Build grid row by row and fill with data from db */
    for (let i = 0; i < findHighestRowNumber; i++) {
      const catData = apps[i];
      const findHighestAppOrder = Math.max(...catData?.edges?.apps?.map(app => app?.order || 0) || [0]);
      const gridRow = buildEmptyRow(findHighestAppOrder, editMode);

      /** Push empty rows to grid */
      grid.categories.push(gridRow);

      /** Fill category data to grid */
      const currentCat = grid.categories[i];
      currentCat.categoryData = catData;

      /** Fill apps to grid */
      catData?.edges?.apps?.forEach((app) => {
        currentCat.apps[app.order!] = [app];
      });
    }
  }

  return {
    buildEmptyRow,
    buildGrid,
  };
}
