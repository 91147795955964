<script setup lang="ts">
import Draggable from 'vuedraggable';
import { useGridStore } from '~/stores/grid';
import { useEditCategoryModal } from '~/utils/editCategoryModal';
import { useAddCategoryModal } from '~/utils/addCategoryModal';
import { useSaveApps } from '~/utils/saveApps';
import { useAppStore } from '~/stores/app';
import { useCacheStore } from '~/stores/cache';
import { useBuildGrid } from '~/utils/buildGrid';

const props = defineProps<{
  editMode: boolean
}>();

const appStore = useAppStore();
const grid = useGridStore();
const cache = useCacheStore();

const router = useRouter();

const { editCategoryModal } = useEditCategoryModal();
const { openAddCategoryModal } = useAddCategoryModal();
const { isSaveAppsLoading, saveAppsError, saveApps } = useSaveApps();
const { buildGrid } = useBuildGrid();

// ToDo: make this configurable
const showCategories = false;

function cancel() {
  saveAppsError.value = '';
  appStore.adminMode! = false;
  router.push('/');
}

watch(cache, () => {
  buildGrid(cache.apps || [], props.editMode);
});
</script>

<template>
  <div class="pb-5">
    <template v-if="grid">
      <Draggable
        :list="grid.categories"
        item-key="row-draggable"
        group="appRows"
        animation="150"
        handle=".rowHandle"
        class="flex flex-col gap-2.5"
        :disabled="!props.editMode"
      >
        <template #item="{ element: rowElement, index: rowIndex }">
          <div class="flex flex-col gap-2.5 lg:flex-row">
            <div
              v-if="showCategories || props.editMode"
              class="flex flex-row-reverse items-center justify-end lg:flex-row lg:items-start lg:justify-start"
            >
              <div
                class="rowHandle h-full min-h-14 flex flex-row items-center justify-center px-4"
                :class="{
                  'opacity-0 ': !props.editMode,
                  'cursor-n-resize ': props.editMode,
                }"
                title="Kategorie verschieben"
              >
                <div class="flex flex-col gap-[3px]">
                  <div class="h-1 w-5 rounded-sm bg-white shadow-[0_0_4px_#0002]" />
                  <div class="h-1 w-5 rounded-sm bg-white shadow-[0_0_4px_#0002]" />
                </div>
              </div>

              <a :href="props.editMode ? null : rowElement.categoryData?.url">
                <div
                  class="h-100% flex cursor-pointer items-start self-center pl-3 pr-8 font-bold transition lt-sm:w-full hover:scale-95"
                  :title="props.editMode ? 'Kategorie bearbeiten' : ''"
                  @click="props.editMode
                    ? editCategoryModal(rowElement.categoryData, rowIndex)
                    : null
                  "
                >
                  <div class="relative">
                    <div
                      v-if="props.editMode"
                      class="i-fa6-solid-gear absolute right--2 top-[50%] z-1 h-4.5 w-4.5 translate-y--6 text-gray-400"
                    />
                    <div
                      v-tooltip="!props.editMode && rowElement.categoryData?.title"
                      :class="`${rowElement.categoryData?.iconName} w-8 h-16 lg:h-34 ml-2 lg:ml-0 text-light-50`"
                    />
                  </div>
                </div>
              </a>
            </div>

            <div class="flex flex-row flex-wrap justify-center gap-2.5">
              <DragBox
                v-for="(columnData, columnIndex) in rowElement.apps"
                :key="`${rowIndex}-${columnIndex}`"
                :row="rowIndex"
                :column="columnIndex as number"
                :data="columnData"
                :edit-mode="props.editMode"
              />
            </div>
          </div>
        </template>

        <template v-if="props.editMode" #footer>
          <div class="flex-between h-35 flex pl-19">
            <div
              v-tooltip="grid.categories.length >= grid.maxRows
                ? `Das Limit von ${grid.maxRows} Kategorien ist erreicht`
                : 'Kategorie hinzufügen'
              "
              class="h-10 w-10 flex items-center self-center justify-center rounded-full text-center shadow"
              :class="grid.categories.length >= grid.maxRows
                ? 'bg-gray-400'
                : 'bg-green-400 anim-scale-95 cursor-pointer'
              "
              @click="grid.categories.length < grid.maxRows && openAddCategoryModal()"
            >
              <div class="i-fa6-solid-plus" />
            </div>
          </div>
        </template>
      </Draggable>
    </template>

    <div
      v-if="props.editMode"
      class="sticky bottom-3 left-7 right-7 z-1 h-15 flex items-center justify-center gap-2 overflow-hidden rounded-md bg-[#fff5] px-1 backdrop-blur-xl md:px-7 sm:px-4"
    >
      <div class="flex-1" />

      <div v-if="saveAppsError" class="text-xs font-normal leading-3 text-red-500 md:text-base md:leading-4">
        {{ saveAppsError }}
      </div>

      <o-button variant="success" :disabled="isSaveAppsLoading" @click="saveApps">
        Speichern
      </o-button>
      <o-button @click="cancel">
        Abbrechen
      </o-button>
    </div>
  </div>
</template>

<style scoped>
.ghost {
    @apply opacity-0;
}
</style>
