<script setup lang="ts">
const props = defineProps<{
  modelValue: string
}>();

const emit = defineEmits(['update:modelValue']);

function updateColor(color: string) {
  emit('update:modelValue', color);
}

const colors = [
  'bg-transparent',
  'bg-light-primary',
  'bg-light-primary',
  'bg-light-primary',
  'bg-light-primary',
  'bg-light-primary',
  'bg-light-primary',
  'bg-light-primary',
  'bg-light-primary',
  'bg-gray-100',
  'bg-gray-200',
  'bg-gray-300',
  'bg-gray-400',
  'bg-gray-500',
  'bg-gray-600',
  'bg-gray-700',
  'bg-gray-800',
  'bg-gray-900',
  'bg-red-100',
  'bg-red-200',
  'bg-red-300',
  'bg-red-400',
  'bg-red-500',
  'bg-red-600',
  'bg-red-700',
  'bg-red-800',
  'bg-red-900',
  'bg-orange-100',
  'bg-orange-200',
  'bg-orange-300',
  'bg-orange-400',
  'bg-orange-500',
  'bg-orange-600',
  'bg-orange-700',
  'bg-orange-800',
  'bg-orange-900',
  'bg-yellow-100',
  'bg-yellow-200',
  'bg-yellow-300',
  'bg-yellow-400',
  'bg-yellow-500',
  'bg-yellow-600',
  'bg-yellow-700',
  'bg-yellow-800',
  'bg-yellow-900',
  'bg-green-100',
  'bg-green-200',
  'bg-green-300',
  'bg-green-400',
  'bg-green-500',
  'bg-green-600',
  'bg-green-700',
  'bg-green-800',
  'bg-green-900',
  'bg-teal-100',
  'bg-teal-200',
  'bg-teal-300',
  'bg-teal-400',
  'bg-teal-500',
  'bg-teal-600',
  'bg-teal-700',
  'bg-teal-800',
  'bg-teal-900',
  'bg-blue-100',
  'bg-blue-200',
  'bg-blue-300',
  'bg-blue-400',
  'bg-blue-500',
  'bg-blue-600',
  'bg-blue-700',
  'bg-blue-800',
  'bg-blue-900',
  'bg-indigo-100',
  'bg-indigo-200',
  'bg-indigo-300',
  'bg-indigo-400',
  'bg-indigo-500',
  'bg-indigo-600',
  'bg-indigo-700',
  'bg-indigo-800',
  'bg-indigo-900',
  'bg-purple-100',
  'bg-purple-200',
  'bg-purple-300',
  'bg-purple-400',
  'bg-purple-500',
  'bg-purple-600',
  'bg-purple-700',
  'bg-purple-800',
  'bg-purple-900',
  'bg-pink-100',
  'bg-pink-200',
  'bg-pink-300',
  'bg-pink-400',
  'bg-pink-500',
  'bg-pink-600',
  'bg-pink-700',
  'bg-pink-800',
  'bg-pink-900',
];
</script>

<template>
  <VDropdown placement="bottom-start" :arrow-padding="20">
    <div :class="`${props.modelValue} rounded h-10 p-2 cursor-pointer border-1 border-dark text-right`">
      {{ props.modelValue.replace('bg-', '') }}
    </div>
    <template #popper="{ hide }">
      <div class="z-10 grid grid-cols-9 max-h-xs gap-1 overflow-y-scroll border border-gray-200 rounded bg-white p-3 pt-2 drop-shadow-xl">
        <div v-for="(color) in colors" :key="color" :class="`${color} rounded text-center cursor-pointer text-3xl m-1 p-3 anim-scale-120`" @click="hide(); updateColor(color)" />
      </div>
    </template>
  </VDropdown>
</template>
