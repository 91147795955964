import { useProgrammatic } from '@oruga-ui/oruga-next';
import AdminEditBubble from '~/components/modals/AdminEditBubble.vue';
import { useGridStore } from '~/stores/grid';
import type { ent_App } from '~/api/generated';

export function useAddBubbleModal() {
  const oruga = useProgrammatic();
  const grid = useGridStore();

  const openAddBubbleModal = (rowIndex: number, columnIndex: number) => {
    oruga.oruga.modal.open({
      component: AdminEditBubble,
      props: {
        bubble: {
          title: 'Titel',
          iconName: 'i-fa6-solid-folder',
          color: 'bg-light-primary',
          iconColor: 'bg-light',
          url: import.meta.env.VITE_URL,
          iframe: false,
          groups: '',
          size: 'large',
          appTypeId: 0,
        } as ent_App,
        title: 'Bubble hinzufügen',
        size: 'large',
        addBubble: true,
      },
      hasModalCard: true,
      trapFocus: true,
      scroll: 'keep',
      animation: 'fade',
      events: {
        save: (result: ent_App) => {
          /** Add new app to grid */
          grid.categories[rowIndex].apps[columnIndex][0] = result;
        },
      },

    });
  };

  return { openAddBubbleModal };
}
