<script  setup lang="ts">
import type { users_User, users_updateUser } from '~/api/generated';
import { UserService } from '~/api/generated';
import type { ApiResponse } from '~/utils/ApiResponse';

interface Props {
  user: users_User
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'reload'): void
}>();

const showMessage = ref<boolean>(false);
const showErrorMessage = ref<boolean>(false);

const isLoading = ref<boolean>(false);

const ldapUpdateUserData = ref<ApiResponse<string>>({ data: undefined, status: undefined });

async function execute() {
  const user: users_updateUser = { blocked: !props.user.blocked };

  isLoading.value = true;

  UserService.ldapUpdateUser(props.user.account_id as string, user)
    .then(result => ldapUpdateUserData.value = { data: result, status: 'success' })
    .catch(() => ldapUpdateUserData.value = { data: undefined, status: 'error' });
}

watch(ldapUpdateUserData, () => {
  isLoading.value = false;

  if (ldapUpdateUserData.value.status === 'success') {
    showMessage.value = true;
    showErrorMessage.value = false;
    emit('reload');
  }
  else if (ldapUpdateUserData.value.status === 'error') {
    showErrorMessage.value = true;
  }
});
</script>

<template>
  <BaseModal
    :title="props.user.blocked ? 'Benutzer freischalten' : 'Benutzer blocken'"
    @submit="execute"
    @close="$emit('close')"
  >
    <template #body>
      <template v-if="isLoading">
        <div class="flex justify-center">
          <div class="flex items-center justify-center">
            <div i="fa6-solid-spinner" class="m-4 h-8 w-8 animate-spin" />
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="!showMessage">
          <div class="alert alert-info">
            Möchten Sie den Benutzer <strong>{{ props.user.name }} ({{ props.user.email }})</strong> wirklich {{ props.user.blocked ? 'freischalten' : 'blocken' }}? <br>
            Die Änderung wird sofort durchgeführt!
          </div>
        </template>
        <div
          v-if="showMessage"
        >
          <div class="alert alert-success">
            Der Benutzer {{ props.user.name }} ({{ props.user.email }}) wurde erfolgreich {{ props.user.blocked ? 'freigeschaltet' : 'geblockt' }}.
          </div>
        </div>

        <div
          v-if="showErrorMessage"
          class="alert alert-danger"
        >
          Fehler beim {{ props.user.blocked ? 'Freischalten' : 'Blocken' }} des Benutzers {{ props.user.name }} ({{ props.user.email }})
        </div>
      </template>
    </template>
    <template #footer>
      <template v-if="!isLoading">
        <div
          v-if="!showMessage"
          class="flex justify-between gap-4"
        >
          <o-button
            @click="$emit('close')"
          >
            Nein
          </o-button>
          <o-button
            native-type="submit"
          >
            Ja
          </o-button>
        </div>
        <div
          v-else
          class="flex justify-end gap-4"
        >
          <o-button variant="success" @click="$emit('close')">
            Ok
          </o-button>
        </div>
      </template>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
