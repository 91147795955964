/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { users_codePayload } from '../models/users_codePayload';
import type { users_generateCodePayload } from '../models/users_generateCodePayload';
import type { users_updateUser } from '../models/users_updateUser';
import type { users_User } from '../models/users_User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Get ldap users paginated
     * @param q Search for dispayName, sAMAccountName or mail
     * @param authorization Insert your access token
     * @returns users_User OK
     * @throws ApiError
     */
    public static ldapGetUsers(
        q: string,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<Array<users_User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'q': q,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Update a ldap user
     * @param id User ID
     * @param user Update user
     * @param authorization Insert your access token
     * @returns string ok
     * @throws ApiError
     */
    public static ldapUpdateUser(
        id: string,
        user: users_updateUser,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: user,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Generates a reset code for a user
     * @param id User ID
     * @param email Email
     * @param authorization Insert your access token
     * @returns users_codePayload OK
     * @throws ApiError
     */
    public static ldapGenerateResetCode(
        id: string,
        email: users_generateCodePayload,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<users_codePayload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/{id}/reset-code',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: email,
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
