<script setup lang="ts">
</script>

<template>
  <div>
    <div class="h-full flex justify-center">
      <div class="h-full flex flex-grow flex-col justify-start">
        <PortalMain />
      </div>
    </div>
  </div>
</template>
