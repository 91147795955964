import { useProgrammatic } from '@oruga-ui/oruga-next';
import AdminEditBubble from '~/components/modals/AdminEditBubble.vue';
import { useGridStore } from '~/stores/grid';
import type { ent_App } from '~/api/generated';

export function useEditBubbleModal() {
  const oruga = useProgrammatic();
  const grid = useGridStore();

  const openEditBubbleModal = (bubble: ent_App, newRow: number, newColumn: number) => {
    oruga.oruga.modal.open({
      component: AdminEditBubble,
      props: {
        bubble,
        title: 'Bubble bearbeiten',
        size: 'large',
      },
      hasModalCard: true,
      trapFocus: true,
      scroll: 'keep',
      animation: 'fade',
      events: {
        save: (result: ent_App) => {
          /** Add new data to grid */
          grid.categories[newRow].apps[newColumn][0] = result;
        },
        delete: (result: ent_App) => {
          if (!result.id)
            return;
          /** Remove app from grid */
          grid.categories[newRow].apps[newColumn] = [];
        },
      },
    });
  };

  return { openEditBubbleModal };
}
