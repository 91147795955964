/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { apps_payload } from '../models/apps_payload';
import type { apps_updateAll } from '../models/apps_updateAll';
import type { ent_App } from '../models/ent_App';
import type { ent_AppGroup } from '../models/ent_AppGroup';
import type { ent_AppType } from '../models/ent_AppType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppService {

    /**
     * Get apps paginated
     * @param edit Edit mode
     * @param authorization Insert your access token
     * @returns ent_AppGroup OK
     * @throws ApiError
     */
    public static getApps(
        edit: boolean,
        authorization?: string,
    ): CancelablePromise<Array<ent_AppGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/apps',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'edit': edit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Create new app
     * @param account Add app
     * @param authorization Insert your access token
     * @returns ent_App OK
     * @throws ApiError
     */
    public static createApp(
        account: apps_payload,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<Array<ent_App>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/apps',
            headers: {
                'Authorization': authorization,
            },
            body: account,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Redirect to guacamole
     * @param server Server
     * @param authorization Insert your access token
     * @param dpi DPI
     * @param w Width
     * @param h Height
     * @returns string OK
     * @throws ApiError
     */
    public static redirectGuacamole(
        server: string,
        authorization?: string,
        dpi?: string,
        w?: string,
        h?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/apps/guacamole',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'server': server,
                'dpi': dpi,
                'w': w,
                'h': h,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Get app types
     * @param authorization Insert your access token
     * @returns ent_AppType OK
     * @throws ApiError
     */
    public static getAppsTypes(
        authorization?: string,
    ): CancelablePromise<Array<ent_AppType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/apps/types',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Redirect to virtuelles klassenbuch
     * @param authorization Insert your access token
     * @returns string OK
     * @throws ApiError
     */
    public static redirectVirtuellesKlassenbuch(
        authorization?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/apps/vk',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Redirect to virtueller stundenplan
     * @param authorization Insert your access token
     * @returns string OK
     * @throws ApiError
     */
    public static redirectVirtuellerStundenplan(
        authorization?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/apps/vs',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Get new mail count
     * @param authorization Insert your access token
     * @returns string OK
     * @throws ApiError
     */
    public static getNewMailCount(
        authorization?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/apps/webmail',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Update app
     * @param id App ID
     * @param app Update app
     * @param authorization Insert your access token
     * @returns ent_App OK
     * @throws ApiError
     */
    public static updateApp(
        id: number,
        app: apps_payload,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<Array<ent_App>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/apps/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: app,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Delete app by id
     * @param id App id
     * @param authorization Insert your access token
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApp(
        id: number,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/apps/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Create new app
     * @param data Add app
     * @param authorization Insert your access token
     * @returns ent_App OK
     * @throws ApiError
     */
    public static updateAll(
        data: apps_updateAll,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<Array<ent_App>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/update-all',
            headers: {
                'Authorization': authorization,
            },
            body: data,
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
