import { useProgrammatic } from '@oruga-ui/oruga-next';
import CachePasswordModal from '~/components/modals/CachePasswordModal.vue';

export function useCachePasswordModal() {
  const oruga = useProgrammatic();

  const openCachePasswordModal = () => {
    oruga.oruga.modal.open({
      component: CachePasswordModal,
      props: {
      },
      hasModalCard: true,
      trapFocus: true,
      scroll: 'keep',
      animation: 'fade',
    });
  };

  return { openCachePasswordModal };
}
