import { useProgrammatic } from '@oruga-ui/oruga-next';
import LogoutTimer from '~/components/modals/LogoutTimer.vue';

export function useLogoutTimerModal() {
  const oruga = useProgrammatic();

  const openLogoutTimerModal = () => {
    oruga.oruga.modal.open({
      component: LogoutTimer,
      props: {
      },
      hasModalCard: true,
      trapFocus: true,
      scroll: 'keep',
      animation: 'fade',
    });
  };

  return { openLogoutTimerModal };
}
