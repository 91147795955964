<script  setup lang="ts">
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Impressum from '~/extern/policy.md';

import { useUserStore } from '~/stores/user';
import type { accounts_updateUser } from '~/api/generated';
import { AccountsService } from '~/api/generated';

const emit = defineEmits<{
  (e: 'close'): void
}>();

const userStore = useUserStore();
const router = useRouter();

const updateAccountData = ref<any | undefined>(undefined);
const accept = ref<boolean>(false);

watch(updateAccountData, () => {
  if (updateAccountData.value !== undefined) {
    if (userStore.user)
      userStore.user.sitePolicyAccepted = true;

    emit('close');
  }
});

async function acceptPolicy() {
  const mailData: accounts_updateUser = { policyAccepted: accept.value };
  updateAccountData.value = await AccountsService.updateAccount(mailData);
}
</script>

<template>
  <BaseModal
    :hide-footer="true"
    size="large"
    :closeable="false"
  >
    <template #body>
      <Impressum />

      <br><hr><br>

      <div class="flex justify-end">
        <o-checkbox
          v-model="accept"
          class="cursor-pointer"
        >
          Nutzungsordnung akzeptieren
        </o-checkbox>
      </div>
      <div class="flex justify-between pt-4">
        <button class="btn btn-danger" @click="router.push('/logout'); emit('close');">
          Abbrechen
        </button>
        <button class="btn btn-primary" :disabled="!accept" @click="acceptPolicy()">
          Absenden
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
