import type { DirectiveBinding } from '@vue/runtime-core';

interface ClickOutsideElement extends HTMLElement {
  __vueClickEventHandler__: any
}

export default {
  beforeMount(el: ClickOutsideElement, binding: DirectiveBinding) {
    const ourClickEventHandler = (event: MouseEvent) => {
      if (!el.contains(event.target as HTMLElement) && el !== event.target)
        binding.value(event);
    };

    el.__vueClickEventHandler__ = ourClickEventHandler;

    document.addEventListener('click', ourClickEventHandler);
  },
  unmounted(el: ClickOutsideElement) {
    document.removeEventListener('click', el.__vueClickEventHandler__);
  },
};
