import { defineStore } from 'pinia';
import type { ent_AppGroup } from '~/api/generated';

export const useCacheStore = defineStore('cache', () => {
  const apps = ref<ent_AppGroup[]>([]);

  return {
    apps,
  };
}, {
  persist: true,
});
