/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { login_codePayload } from '../models/login_codePayload';
import type { login_loginPayload } from '../models/login_loginPayload';
import type { login_LogoutResult } from '../models/login_LogoutResult';
import type { login_oidcLogin } from '../models/login_oidcLogin';
import type { login_passwordPayload } from '../models/login_passwordPayload';
import type { login_refreshTokenPayload } from '../models/login_refreshTokenPayload';
import type { login_Result } from '../models/login_Result';
import type { login_User } from '../models/login_User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LoginService {

    /**
     * Login endpoint
     * @param login Credentials
     * @returns login_Result OK
     * @throws ApiError
     */
    public static login(
        login: login_loginPayload,
    ): CancelablePromise<login_Result> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/login',
            body: login,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Login Endpoint for OpenID Connect
     * @param login Credentials
     * @param authorization Insert your access token
     * @returns any OK
     * @throws ApiError
     */
    public static openIdConnect(
        login: login_oidcLogin,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/login/oidc',
            headers: {
                'Authorization': authorization,
            },
            body: login,
        });
    }

    /**
     * Cache password endpoint
     * @param login Password
     * @returns login_Result OK
     * @throws ApiError
     */
    public static cachePassword(
        login: login_passwordPayload,
    ): CancelablePromise<login_Result> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/login/password',
            body: login,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Endpoint for refreshing a token
     * @param refreshToken Refresh token
     * @returns any OK
     * @throws ApiError
     */
    public static refreshToken(
        refreshToken: login_refreshTokenPayload,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/login/refresh',
            body: refreshToken,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Login Endpoint for SAML
     * @param authorization Insert your access token
     * @returns any OK
     * @throws ApiError
     */
    public static samlSession(
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/login/saml',
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * Logout endpoint
     * @returns login_LogoutResult OK
     * @throws ApiError
     */
    public static logout(): CancelablePromise<login_LogoutResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/logout',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Endpoint for generating a TOTP code
     * @param authorization Insert your access token
     * @returns any OK
     * @throws ApiError
     */
    public static generateTotpCode(
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/totp/code',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Endpoint for deleting a TOTP code
     * @param authorization Insert your access token
     * @returns any OK
     * @throws ApiError
     */
    public static deleteTotpCode(
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/totp/code',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Endpoint for verifying a TOTP code
     * @param code Code
     * @param authorization Insert your access token
     * @returns any OK
     * @throws ApiError
     */
    public static verifyTotpCode(
        code: login_codePayload,
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/totp/code/verify',
            headers: {
                'Authorization': authorization,
            },
            body: code,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Me endpoint
     * @param authorization Insert your access token
     * @returns login_User OK
     * @throws ApiError
     */
    public static getMe(
        authorization: string = 'Bearer <Add access token here>',
    ): CancelablePromise<login_User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/me',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
